html, body {
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;

  background-color: #eee;
}

.site-container {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
}

.header {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-content: center;

  margin: 0.1rem 1rem 0.5rem 1rem;
  border-bottom: 2px solid black;
}

.header em.wip {
  font-size: 14px;
  vertical-align: super;
}

.header-section {
  display: flex;
  justify-content: row;
  align-content: center;
  margin: auto 0;
}

.header span.ph {
  font-size: 28px;
}

.header a {
  color: black;
  text-decoration: none;
}

.content {
  margin: auto 3rem;
  flex-grow: 1;
}

.footer {
	padding: 0.5rem 0 0.5rem 0;

	display: flex;
	flex-direction: column;
	justify-content: center;
  align-content: center;

  margin: 1rem 5rem 0.5rem 5rem;
  border-top: 2px solid lightgrey;
}

.footer-section {
  display: flex;
	flex-flow: row wrap;
	justify-content: center;
	gap: 0 1rem;
}

.no-gpu {
  border: 2px solid grey;
  background-color: #fbb;

  padding: 10px;
  margin: 20px;
}

.editor-container {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;

}

.editor-container > * {
  min-width: 300px;
  margin: 5px;
}

.display {
	display: flex;
	flex-direction: row;
	justify-content: center;
}

.display > canvas {
  display: none;
}

.editor {
  flex-grow: 1;
  min-height: 100px;
}

.editor #editor {
  background-color: #f2f2f2;
  border: 1px solid black;
  margin-bottom: 5px;
}

.error-line {
  background-color: #fbb;
  border: 1px solid black;

  margin-right: 10px;
  margin-left: 5px;
  margin-top: 10px;
  padding: 2px;

  color: #555;
}

#compile {
  background: url("./hammer-fill.svg") no-repeat scroll 0px 2px;

  border: 2px solid black;
  border-radius: 5px;

  padding-left: 30px;
  padding-bottom: 18px;
}

#compile:hover {
  background-color: #e2f2e2;
}

#compile:active {
  background-color: #c2f2c2;
}
