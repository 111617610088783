html, body {
  background-color: #eee;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.site-container {
  flex-direction: column;
  min-height: 100vh;
  display: flex;
}

.header {
  border-bottom: 2px solid #000;
  flex-flow: wrap;
  place-content: center space-between;
  margin: .1rem 1rem .5rem;
  display: flex;
}

.header em.wip {
  vertical-align: super;
  font-size: 14px;
}

.header-section {
  justify-content: row;
  align-content: center;
  margin: auto 0;
  display: flex;
}

.header span.ph {
  font-size: 28px;
}

.header a {
  color: #000;
  text-decoration: none;
}

.content {
  flex-grow: 1;
  margin: auto 3rem;
}

.footer {
  border-top: 2px solid #d3d3d3;
  flex-direction: column;
  place-content: center;
  margin: 1rem 5rem .5rem;
  padding: .5rem 0;
  display: flex;
}

.footer-section {
  flex-flow: wrap;
  justify-content: center;
  gap: 0 1rem;
  display: flex;
}

.no-gpu {
  background-color: #fbb;
  border: 2px solid gray;
  margin: 20px;
  padding: 10px;
}

.editor-container {
  flex-flow: wrap;
  justify-content: center;
  display: flex;
}

.editor-container > * {
  min-width: 300px;
  margin: 5px;
}

.display {
  flex-direction: row;
  justify-content: center;
  display: flex;
}

.display > canvas {
  display: none;
}

.editor {
  flex-grow: 1;
  min-height: 100px;
}

.editor #editor {
  background-color: #f2f2f2;
  border: 1px solid #000;
  margin-bottom: 5px;
}

.error-line {
  color: #555;
  background-color: #fbb;
  border: 1px solid #000;
  margin-top: 10px;
  margin-left: 5px;
  margin-right: 10px;
  padding: 2px;
}

#compile {
  background: url("hammer-fill.0a7c46c4.svg") 0 2px no-repeat;
  border: 2px solid #000;
  border-radius: 5px;
  padding-bottom: 18px;
  padding-left: 30px;
}

#compile:hover {
  background-color: #e2f2e2;
}

#compile:active {
  background-color: #c2f2c2;
}
/*# sourceMappingURL=index.bf99f315.css.map */
